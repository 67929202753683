@import "../../styles/variables";

.container {
    font-family: "Avenir";
    border: 1px solid $border_color;
    border-radius: 5px;
    padding: 30px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
        color: #1B1B1B;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
    .content {
        margin-top: 16px;
        gap: 30px;
        display: flex;
        align-items: flex-end;
        .description {
            font-size: 20px;
            font-weight: 300;
        }
        img {
            width: 52px;
            height: 52px;
        }
    }
}