.hero {
    width: 100%;
    height: 800px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 20.83%, rgba(0, 0, 0, 0.74) 90.1%),
    url('../../assets/about-us-hero.png');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
        color: #FFF;
        text-align: center;
        font-family: 'Avenir';
        font-size: 64px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        max-width: 60%;
    }
}

.about-us-content {
    max-width: 90%;
    margin: 0 auto;
    padding-top: 150px;
    position: relative;
    z-index: 100;
    .inner-container {
        margin: 0 20px;
    }
    .right-fig {
        position: absolute;
        right: -5%;
        top: 30px;
        z-index: 0;
        @media (max-width: 970) {
            display: none;
        }
    }
    .content-container {
        margin-bottom: 60px;
        .title {
            color: #393D3B;
            font-family: 'Bebas Neue';
            font-size: 50px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 40px;
        }
        .description {
            color: #393D3B;
            /* Heading 4/paragraph */
            font-family: 'Avenir';
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-width: 75%;
            @media (max-width: 970px) {
                font-size: 20px;
            }
        }
    }
    .content-w-img {
        display: flex;
        gap: 60px;
        margin-bottom: 60px;
        position: relative;
        z-index: 100;
        .content-container {
            flex: 1;
            .description {
                max-width: 100%;
            }
        }
        img {
            flex: 1;
            max-width: 700px;
            border-radius: 45px;
        }
    }
}

.gallery-img {
    width: 100%;
    object-fit: cover;
}

.gallery-swiper {
    margin-bottom: 75px;
}

.facilities {
    position: relative;
    z-index: 20;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 220px;
    h1 {
      text-align: center;
      margin: 36px 0;
    }
    .facilities-desc {
        color: #000;
        text-align: center;
        /* Heading 4/paragraph */
        font-family: 'Avenir';
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 70%;
        margin: 0 auto;
        margin-bottom: 60px;
    }
    .swiper-view {
      display: none;
    }
    .facility-list {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 30px;
    }
    .facilities-swiper {
      .swiper-slide {
        width: 85% !important;
      }
    }
  }

  .book-btn {
    margin-top: 2rem;
  }

  .our-rooms-about-us {
    padding: 120px 0;
    min-height: 800px;
    width: 100%;
    background-color: #D5C8B8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #393D3B;
    .section-title {
      font-size: 50px;
      text-align: center;
      font-weight: 400;
      color: #393D3B;
      margin-bottom: 20px;
    }
    .section-desc {
        font-family: 'Avenir';
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 75%;
        margin: 0 auto;
        margin-bottom: 80px;
    }
  }

@media (max-width: 1100px) {
  .facilities {
    padding: 0;
    .swiper-view {
      display: block;
    }
    .facility-list {
      display: none;
    }
  }
}

@media (max-width: 970px) {
  .our-rooms-about-us {
    .section-title {
      width: 90%;
      margin: 25px auto;
      text-align: left;
    }
    .section-desc {
      max-width: 90%;
    }
  }

    .hero {
        align-items: flex-end;
        .title {
            font-size: 24px;
            max-width: 80%;
            margin-bottom: 80px;
        }
    }
    .about-us-content {
        padding-top: 60px;
        .inner-container {
            margin: 0;
        }
        .right-fig {
            display: none;
        }
        .content-container {
            margin-bottom: 30px;
            .description {
                font-size: 20px;
                max-width: 100%;
            }
        }
        .content-w-img {
            flex-direction: column-reverse;
            margin-bottom: 30px;
            img {
                border-radius: 14px;
            }
        }
    }
      .gallery-swiper {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 30px;
      }
}