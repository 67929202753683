@import "./styles/variables";

header {
  position: absolute;
  top: 0;
  display: flex;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
  align-items: center;
  @media (max-width: 970px) {
    justify-content: center;
  }
}

.links {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  @media (max-width: 970px) {
    display: none;
  }
  li {
    color: white;
    font-size: 50px;
    font-style: normal;
    line-height: normal;
  }
}

.hero {
  width: 100%;
  height: 900px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about-section {
  position: relative;
  .figure {
    position: absolute;
  }
  .fig-right {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 0;
  }
  .fig-left {
    left: 0;
    top: 0;
    z-index: 0;
  }
}

.fig-left-mobile {
  top: 50%;
  left: -10%;
  bottom: 0;
  z-index: 0;
  display: none;
}

.showcase {
  max-width: 1220px;
  margin: 0 auto;
  padding: 300px 0;
  display: flex;
  gap: 75px;
  position: relative;
  z-index: 20;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h2 {
      color: #393D3B;
      font-size: 50px;
      font-style: normal;
      line-height: normal;
    }
    .description {
      color: #393D3B;
      font-family: 'Avenir';
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.mobile {
  display: none;
}

.facilities {
  position: relative;
  z-index: 20;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 220px;
  .section-title {
    text-align: center;
    margin-bottom: 36px;
  }
  .swiper-view {
    display: none;
  }
  .facility-list {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 30px;
  }
  .facilities-swiper {
    .swiper-slide {
      width: 85% !important;
    }
  }
}

.facility-nav {
  display: none;
}

.our-rooms {
  min-height: 800px;
  width: 100%;
  background-color: #D5C8B8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2rem;
  .section-title {
    font-size: 50px;
    color: #fff;
    margin: 4rem 0;
    text-align: center;
  }
}

.room-card {
  border-radius: 26px;
  background: #FFF;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.25);
  width: 90%;
  min-height: 250px;
  padding: 14px;
  padding-bottom: 36px;
  color: #393D3B;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: height 1s;
  margin: 10px 0;
  .room-name {
    text-align: center;
    font-family: 'Bebas Neue';
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;
  }
  .room-description {
    text-align: center;
    font-family: 'Avenir';
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .room-img {
    border-radius: 18px;
    width: 100%;
    height: 170px;
    object-fit: cover;
  }
  .room-details {
    color: #393D3B;
    text-align: center;
    /* paragraph 2 */
    font-family: 'Avenir';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 40px;
  }
  .room-details-secondary {
    color: #393D3B;
    text-align: center;
    /* paragraph 2 */
    font-family: 'Avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 20px;
  }
}

.satisfied-clients {
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .clients-swiper {
    width: 80%;
    margin: 0 auto;
  }
  .clients-title {
    font-size: 50px;
    color: #000;
    margin-bottom: 70px;
    text-align: center;
  }
  .review-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .reviewer-img {
      width: 180px;
      height: 180px;
      border-radius: 14px;
      background-color: #D9D9D9;
      margin-bottom: 36px;
    }
    .name {
      color: #393D3B;
      text-align: center;
      font-family: 'Avenir';
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-bottom: 15px;
    }
    .text {
      color: #000;
      text-align: center;
      font-family: 'Avenir';
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-bottom: 15px;
      min-height: 165px;
    }
  }
}

@keyframes details-anim {
  from { height: 0%;}
  to {height: 100%;}
}

@media (max-width: 1100px) {
  .facilities {
    padding: 0;
    .swiper-view {
      display: block;
    }
    .facility-list {
      display: none;
    }
  }
  .facility-nav {
    display: flex;
    justify-content: center;
    gap: 45px;
    margin-top: 80px;
    margin-bottom: 60px;
    :hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 970px) {
  .mobile {
    display: block;
  }
  .read-more {
    display: flex;
    flex-direction: row-reverse;
    width: 90%;
    margin-bottom: 150px;
    position: relative;
    z-index: 999;
  }
  .showcase {
    max-width: 90%;
    padding: 75px 0;
    flex-direction: column-reverse;
    position: relative;
    z-index: 20;
    .showcaseImg {
      position: relative;
      z-index: 20;
    }
    .btn {
      display: none;
    }
    .content {
      position: relative;
      z-index: 20;
      margin-top: 100px;
      .description {
        display: none;
      }
    }
  }
  .figure {
    display: none;
  }
  .fig-left-mobile {
    display: block;
    position: absolute;
  }
}