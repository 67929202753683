@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Barlow:wght@500&display=swap');

body {
  margin: 0;
  font-family: 'Bebas Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  width: 100%;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

a {
  color: #fff;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {

}
