@import "../../styles/variables";

header {
  position: absolute;
  top: 0;
  display: flex;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  @media (max-width: 970px) {
    justify-content: center;
  }
}

header.active {
  overflow: hidden;
  background-color: rgba(0,0,0,0.7);
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 9999999;
  position: fixed;
  top: 0;
  transition: background-color 2s;
}

.links {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  @media (max-width: 970px) {
    display: none;
  }
  li {
    color: white;
    font-size: 50px;
    font-style: normal;
    line-height: normal;
  }
}

.links.active {
  display: block;
  padding: 0;
  flex-direction: column;
  li {
    text-align: center;
  }
  transition: display 2s;
}