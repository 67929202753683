@import "../../styles/variables";

footer {
    height: 973px;
    width: 100%;
    background-color: #393D3B;
    color: white;
    display: flex;
    position: relative;
}

.contact-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-left: 187px;
    margin-top: 180px;
    .title {
        font-size: 54px;
        font-style: normal;
        line-height: normal;
    }
}

.contact {
    .type {
        font-size: 40px;
        font-style: normal;
    }
    .content {
        font-family: "Avenir";
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
}

.illustration {
    width: 50%;
    height: 100%;
    object-fit: cover;
}

.decoration {
    width: calc(100% - 60px);
    position: absolute;
    bottom: 60px;
    left: 60px;
    z-index: 20;
    img {
        object-fit: contain;
        width: 200px;
        margin-bottom: -50px;
    }
    .line {
        background-color: white;
        height: 8px;
    }
}

@media (max-width: 1024px) {
    footer {
        flex-direction: column-reverse;
        height: 1360px;
    }
    .contact-info {
        height: 973px;
        width: fit-content;
        margin-left: 55px;
        margin-top: 55px;
    }
    .illustration {
        width: 100%;
        height: 384px;
    }
    .contact>.content {
        font-size: 24px;
    }
    .decoration {
        width: 100%;
        left: 0;
    }
}