@import "../../styles/_variables.scss";

.search {
    width: 70%;
    height: 170px;
    border-radius: 34px;
    position: absolute;
    transform-origin: center;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 999;
    background-color: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    .searchBtn {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #E5E5E5;
      :hover {
        cursor: pointer;
      }
      .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          img {
            width: 50px;
            height: 50px;
            color: #888888;
          }
          p {
            color: #000;
            font-variant-numeric: lining-nums proportional-nums;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: 'Barlow';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 29px;
          }
          .secondary_txt {
            color: $accent_color;
          }
      }
    }
    .search-submit {
      flex: 2;
      background-color: $primary_color;
      border-radius: 0 34px 34px 0;
      span {
        color: $text_color_secondary;
        text-align: center;
        /* Heading 1 */
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

.counter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    .counter-btn {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-color: #F2F2F2;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 15px;
            height: 15px;
            object-fit: cover;
        }
    }
}

.guests-picker {
  padding: 1rem;
  display: flex;
  background: #fff;
  flex-direction: row;
  gap: 1.5rem;
  .counter-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    .counter {
      border-radius: 5px;
      border: 1px solid $border_color;
      padding: 12px 10px;
    }
  }
}

.react-calendar {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: none !important;
    border-radius: 17px;
    padding: 4px 8px;
    .react-calendar__tile {
      border-radius: 50%;
      aspect-ratio: 1/1;
    }
    .react-calendar__tile--now {
        background: transparent;
        border: 2px solid $primary_color;
    }
    .react-calendar__tile--active {
      background: $primary_color !important;
    }
}


@media (max-width: 970px) {
    .search {
        border-radius: 0 34px 34px 0;
        left: 0;
        top: 50%;
        transform: translateY(50%);
        .input {
            display: none;
        }
    }
}