.btn {
    display: inline-flex;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #D5C8B8;
    width: fit-content;
    :hover {
        cursor: pointer;
    }
    h3 {
        color: #393D3B;
        font-family: 'Bebas Neue';
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}